import React, {useState, FormEvent} from 'react'
import {Link, navigate} from 'gatsby'
import PropTypes from 'prop-types'
import Layout from '../components/layout'
import Head from '../components/head'
import {useAuth} from '../util/use-auth'
import withLocation, {WithLocationProps} from '../util/with-location'

const isBrowser = typeof window !== `undefined`

type Props = WithLocationProps

const Confirm: React.FC<Props> = ({search}) => {
  const searchEmail = search
    ? search.email
      ? typeof search.email === 'string'
        ? search.email
        : search.email[0]
      : ''
    : ''
  const searchConfirmationCode = search
    ? search.code
      ? typeof search.code === 'string'
        ? search.code
        : search.code[0]
      : ''
    : ''

  const [origEmail] = useState(searchEmail)
  const [email, setEmail] = useState(searchEmail)
  const [confirmationCode, setConfirmationCode] = useState(searchConfirmationCode)
  const [message, setMessage] = useState(null as string | null)
  const [isLoading, setIsLoading] = useState(false)

  const auth = useAuth()

  const handleResendClick = async (event: FormEvent): Promise<void> => {
    event.preventDefault()
    if (!auth) return
    setMessage(null)
    setIsLoading(true)
    try {
      await auth.resendSignUp(email)
    } catch (error) {
      setMessage((error as any).message)
      console.error(error)
    }
    setIsLoading(false)
  }

  const validateConfirmationForm = (): boolean => {
    return email.length > 0 && confirmationCode.length > 0
  }

  const handleConfirmationSubmit = async (event: FormEvent): Promise<void> => {
    event.preventDefault()
    if (!auth) return
    setMessage(null)
    setIsLoading(true)
    try {
      await auth.confirmSignUp(confirmationCode, email)
      // Send them to the login
      const state = {
        flash: 'Thanks for confirming your email address. To complete the process please login.',
      }
      navigate('/login/', {state})
    } catch (error) {
      setMessage((error as any).message)
      console.error(error)
    }
    setIsLoading(false)
  }

  if (!isBrowser) {
    return <></>
  }

  return (
    <Layout>
      <Head title="Confirm" />
      <div>
        <form onSubmit={handleConfirmationSubmit}>
          <p>
            Before you can continue we need to verify that the email address you entered is using a confirmation code.
            {origEmail && (
              <>
                We‘ve sent a confirmation email to <strong>{origEmail}</strong>
              </>
            )}
          </p>
          <label>
            Email
            <br />
            <input
              name="email"
              type="email"
              value={email}
              onChange={(e): void => {
                setMessage(null)
                setEmail(e.target.value)
              }}
            />
          </label>
          <br />
          <label>
            Confirmation Code
            <br />
            <input type="tel" value={confirmationCode} onChange={(e): void => setConfirmationCode(e.target.value)} />
          </label>
          <br />
          <div className="error">{message}</div>
          <div>
            <button disabled={!validateConfirmationForm() || isLoading}>
              {isLoading ? 'Confirming...' : 'Confirm'}
            </button>
            <button disabled={!email || isLoading} onClick={handleResendClick} style={{float: 'right'}}>
              Resend
            </button>
          </div>
        </form>
      </div>
      <br />
      <br />

      <Link to="/">Go back to the homepage</Link>
    </Layout>
  )
}

Confirm.propTypes = {
  search: PropTypes.any,
}

export default withLocation(Confirm)
